<template>
    <div style="height:100%;">
        <a-layout style="height:100%;">
            <a-layout-content style="height:100%;">
                <div class="yj-conten" style="min-height: calc(100% - 48px);background-color:#fff;min-width:max-content;width:calc(100% - 32px)">
                    <a-layout style="height:100%;background:#fff;padding:0 20px;">
                        <a-layout-header style="height:auto;background:#fff;padding:16px 0 ;">
                            <a-row>
                                <a-col class="textal_l">
                                    <a-button type="primary" @click="EditRow(0)">上传视频</a-button>
                                </a-col>
                            </a-row>
                        </a-layout-header>
                        <a-table :columns="columns" rowKey="ID" :data-source="dataList" :pagination="pagination" @change="pagerChange">
                            <span slot="action" slot-scope="text, record">
                                <a-button type="link" size="small" @click="EditRow(record.ID)">编辑</a-button>
                            </span>
                            <span slot="Video" slot-scope="text, record">
                                <a @click="ShowVideo(record.Video)"><video name="media" width="50" height="50"><source :src=record.Video /></video></a>
                            </span>
                        </a-table>
                    </a-layout>
                </div>
            </a-layout-content>
        </a-layout>
        <a-modal v-model="modalVisible" title="视频">
            <video :src=Video autoplay="autoplay" loop="loop" width="480"></video>
            <div slot="footer"></div>
        </a-modal>
    </div>
</template>
<script>
    import util from "@/Plugin/util.js";
    import http from "@/Plugin/Http.js";
    export default {
        name: "MarketVideo_List",
        data() {
            return {
                columns: [
                    {
                        title: "标题",
                        customRender: (text, item) => {
                            var src = item.Title;
                            return src;
                        }
                    },

                    {
                        title: "封面",
                        // scopedSlots: { customRender: 'Video' },
                        customRender: (text, item) => {
                            var src = item.Image;
                            return <div><img src={src} width="50px" height="50px" /></div>;
                        }
                    },
                    {
                        title: "视频",
                        scopedSlots: { customRender: 'Video' },
                    },
                    {
                        title: "创建时间",
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.InputTime, 'yyyy-MM-dd hh:mm:ss')
                        }
                    },
                    {
                        title: "操作",
                        align: "center",
                        scopedSlots: { customRender: 'action' },
                    }
                ],
                dataList: [],
                pagination: {
                    total: 0,
                    defaultPageSize: 10,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                Video: '',
                modalVisible: false,
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            EditRow(val) {
                this.$router.push({
                    name: "MarketVideo_Detail",
                    query: { ID: val }
                });
            },
            loadData() {
                var self = this;
                var op = {
                    url: "/MarketingModule/MarketVideo/GetMarketVideoList",
                    data: {
                        PageIndex: self.pagination.current
                    },
                    OnSuccess: function (res) {
                        self.dataList = res.data.Data;
                        self.pagination.total = res.data.Total;
                        self.pagination.defaultPageSize = res.data.RowCount;
                        self.pagination.current = res.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.loadData();
            },
            ShowVideo(e) {
                var self = this;
                self.Video = e;
                self.modalVisible = true;
            },
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.loadData();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>
